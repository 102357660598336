<template>
  <div class="pay">
    <div class="checkout-page">
      <p>
        <img src="@/assets/mpesa-icon.png" alt="Error" class="mpesa-icon" />
        <span class="span1">Ksh {{ product.fee }}</span> for {{ product.title }}
      </p>
      <input
        type="text"
        id="phone"
        class="phone-input"
        placeholder="Enter phone number"
        maxlength="10"
        v-model="phoneNumber"
        @input="validatePhoneInput"
      />
      <p id="validation-message">{{ validationMessage }}</p>
      <button :disabled="isProcessing" @click="payNow">
  {{ isProcessing ? "Processing..." : "Pay Now" }}
</button>
   <!-- Loading Spinner -->
   <div v-if="isProcessing" class="loading-spinner">
        <div class="spinner"></div>
        <p>Processing your payment...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import pinia from '@/store/pinia.js'
import { useUserStore } from '@/store/user.js';
const userStore = useUserStore(pinia);
export default {
  data() {
    return {
      phoneNumber: "",
      userName:"",
      mac:"",
      ip:"",
      productId:0,
      product:"",
      intervalId:0,
      validationMessage: "",
      isProcessing: false, // New flag to track payment processing
    };
  },
  created() {
    // console.log(process.env.VUE_APP_API_URL)
    console.log("Mpesa Pay:created")
    this.product = userStore.product;
    this.mac = userStore.mac;
    this.ip = userStore.ip;
    console.log(userStore.product)
 
  },
  methods: {
    validatePhoneInput() {
      this.phoneNumber = this.phoneNumber.replace(/\D/g, "");
      this.validationMessage =
        this.phoneNumber.length === 10
          ? "Valid input"
          : "Please enter exactly 10 digits";
    },
    payNow() {

      // Validate phone number input first
      if (this.phoneNumber.length !== 10) {
        this.validationMessage = "Please enter exactly 10 digits.";
        return; // Prevent further execution if the phone number is invalid
      }

      // Check if a payment process is already ongoing
      if (this.isProcessing) {
        console.warn("Payment already in progress, please wait.");
        return; // Prevent duplicate requests
      }

      this.isProcessing = true; // Disable button during processing
      console.log("Initiating payment process...");

      // Continue with payment only if phone number is valid
      if (cookies.isKey("et-userName")) {
        this.userName = cookies.get("et-userName");
      }
      // let token = cookies.get("token");

      // cookies.set(关键值（keyName），参数值（value），过期时间（expireTimes）)
      // cookies.remove(keyName)
      // cookies.isKey("keyName") //返回的是boolean(true or false) 

      let postParams = {
        userName: this.userName,
        phoneNumber: this.phoneNumber,
        productId: this.product.id,
        mac: this.mac,
        ip: this.ip,
      };
      console.log("Payment parameters:", postParams);
      var that = this;
      fetch(process.env.VUE_APP_API_URL + "/hotspot/pay", {
        method: "POST",
        body: JSON.stringify(postParams),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // console.log("POST Data:", data);
          cookies.set("et-userName", data.data.userName, "100d");
          that.userName = data.data.userName;
          that.paymentId = data.data.paymentId;
          if (data.data.mpesaCode === "0") {
            console.log("check pay status.")
            that.checkPayStatus();
          } else {
            that.$router.push("/error"); // Error page
          }
        })
        .catch((error) => {
          console.error("Payment error:", error);
          that.$router.push("/error");
        })
        .finally(() => {
          // that.isProcessing = false; // Re-enable button
          console.log("Payment process completed.");
        });
    },
    checkPayStatus(){
      var that = this;
      this.intervalId = setInterval(function() {
        let postParams = { 
          userName: that.userName,
          paymentId: that.paymentId
         };
         console.log("pay status params:", postParams)
        fetch(process.env.VUE_APP_API_URL + "/hotspot/payStatus", {
          method: 'POST',
          body: JSON.stringify(postParams),
          headers: {
            'Content-Type': 'application/json'
          },
        })
          .then(response => response.json())
          .then(data => {
            console.log('POST Data:', data)
            if ("3" == data.data.status) {
              console.log("no mpesa callback, continue.")
            } else if ("1" != data.data.status) {
              clearInterval(that.intervalId);
              that.$router.push("/success");
              // clearInterval(intervalId);
            } else {
              clearInterval(that.intervalId);

              that.$router.push("/error");
              // clearInterval(intervalId);
            }

          })
          .catch(error => {
          console.error('Error:', error)
        }).finally(() => {
          clearInterval(that.intervalId);
        });
      }, 10000);

    }
  },
};
</script>


<style scoped>
.pay {
  background: whitesmoke;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -100px;
  padding-top: 30px;
  padding-bottom: 50px;
  border-radius: 24px;
  width: auto;
  height: 100%;
}

.checkout-page {
  background-color: #202b61;
  text-align: center;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border-radius: 12px;
  height: auto;
  margin: 50px;
  margin: auto;
}

/* Media Query for Small Screens */
@media (max-width: 500px) {
  .checkout-page {
    margin-top: 20px; /* Reduce the top margin on smaller screens */
    margin-bottom: 20px; /* Reduce the bottom margin on smaller screens */
    padding: 10px; /* Reduce padding for smaller screens */
    max-width: 800px
  }

  .pay {
    padding-top: 35px; /* Reduce padding on the top of .pay */
    padding-bottom: 35px; /* Reduce padding on the bottom of .pay */
    margin-top: 10px;
  }
}

input {
  padding-left: 10px;
  padding: 5px;
  color: #fff;
  border-style: double;
  border-color: #ffff;
  background-color: transparent;
  width: 100%;
  max-width: 80%;
  margin-bottom: 23px;
  border-radius: 16px;
  height: 35px;
  margin-top: 8px
}

.mpesa-icon {
  width: 30px;
  margin-bottom: -6px;
}

p {
  font-weight: 600;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-bottom: 10px;
  color: white;
  margin-top: 30px;
}

.span1 {
  color: #00ff00;
}

button {
  background: #007bff;
  color: #fff;
  padding: 15px;
  width: 100%;
  max-width: 70%;
  margin-bottom: 30px;
  border-radius: 16px;
  border-style: none;
  background-color: #00ff00;
  transition: 0.3s ease;
}

button:hover {
  background-color: #02cf02;
  cursor: pointer;
}

::placeholder {
  color: #fff;
}

#validation-message {
  font-size: 14px;
  margin-top: -15px;
  margin-bottom: 20px;
}

#validation-message.valid {
  color: green;
}

#validation-message.invalid {
  color: red;
}

span {
  color: #00ff00;
}

/* Add spinner and loading state styles */
.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
