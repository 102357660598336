import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
    // state:() => {
    //     return {
    //         token: "",
    //         productId:"",
    //         productTitle:"",
    //         productFee:"",
    //         phoneNumber:""
    //     };
    // },
    state:() => ({
            userName: "",
            productId:0,
            paymentId:0,
            mac:"",
            ip:"",
            product:{}
        }),
    
    getters:{},
    actions:{}

})