<template>
  <div class="package-selection">
    <div v-for="(product, index) in productList" :key="index" class="package">
      <!-- Display the duration (formatted) and price of the package -->
      <p class="time">{{ product.title }}</p>
      <p class="ksh"><span>Ksh</span> {{ product.fee }}</p>
      <!-- Pass only duration and price to the checkout page -->
      <button @click="goToPay(product)">Get Access</button>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia'
import pinia from '@/store/pinia.js'
import { useUserStore } from '@/store/user.js';
const userStore = useUserStore(pinia);
// console.log(userStore.token)

export default {
  data() {
    return {
      productList: [], 
      mac:"",
      ip:"",
    };
  },
  mounted() {
    this.fetchProductList(); // Fetch packages when component is mounted
  },
  created(){
    const urlParams = new URLSearchParams(window.location.search);
    this.ip = urlParams.get('ip');
    this.mac = urlParams.get('mac');
    // this.mac = "4E:44:8E:83:FB:03";
    // this.ip = "10.23.101.4";
    console.log(this.mac);
    console.log(this.ip);
    console.log(urlParams)
  },
  methods: {
    // Fetch packages from the backend API
    fetchProductList() {
      // fetch("https://task.etnet.co.ke/api/street_packages")
      fetch(process.env.VUE_APP_API_URL + "/hotspot/productList")
        .then(response => {
          if (!response.ok) {
            throw new Error("Failed to fetch product");
          }
          return response.json();
        })
        .then((data) => {
          console.log("data:", data)
          this.productList = data.data;
        })
        .catch((error) => {
          console.error("Error fetching product:", error);
        });
    },
    // Navigate to the checkout page with package details
    goToPay(productItem) {
      // let {product, mac, ip} = storeToRefs(userStore);
      const userInfo = storeToRefs(userStore);
      userStore.product = productItem;
      userStore.mac = this.mac;
      userStore.ip = this.ip;

      // const userInfo = ()=>{
	      //  userStore.$patch({
		    //   product = "productItem",
		    //   mac = this.mac,
		    //   ip = this.ip
	      // })
      // }
      this.$router.push({ name: "pay" });
    //   query: {
    //       showTitle: title,
    //       showFee: fee,
    //       productId: 66,
    //       fee: 'qll',
    //       mac:"",
    //       ip:""
    // }
    },
  },
};
</script>

<style scoped>
.package-selection {
  background: whitesmoke;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -90px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 24px;
  width: auto;
}

.package {
  border-radius: 12px;
  background: #fff;
  padding: 1px;
  margin: 10px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.time {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #202b61;
  margin-bottom: -45px;
}

.ksh {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #704fde;
  font-size: 50px;
  font-weight: 900;
  margin-bottom: -0.1px;
}

span {
  font-size: 20px;
  font-weight: 900;
}

button {
  background: #f99526;
  color: #fff;
  font-weight: 900;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 8px;
  border-radius: 12px;
  width: 70%;
  margin-bottom: 15px;
  outline-style: none;
  border-style: none;
  transition: 0.3s ease;
}

button:hover {
  background-color: #eb7e03;
  cursor: pointer;
}
</style>
