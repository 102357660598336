import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css';
import VueCookies from 'vue3-cookies'
// import {createPinia} from 'pinia'
// import { createPinia } from 'pinia'
// import { store } from './store/pinia';
import pinia from './store/pinia.js'

const app = createApp(App);
// const pinia = createPinia();

app.use(VueCookies)
// app.use(pinia)
app.use(router)
app.use(pinia)
app.mount('#app')
