<template>
  <div class="app">
    <header class="header">
      <img src="@/assets/logo.png" alt="Logo" class="logo">
      <img src="@/assets/wifi-icon.png" alt="Logo" class="wifi-icon">
    </header>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <footer class="footer">
      <div class="contacts">
        
        <p class="cont">Phone: 0791012345</p>
        <p class="cont">Email: sales@etnet.co.ke</p>
        
      </div>
      </footer>
  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style scoped>
.header {
  background-color: #fff;
  margin-top: -55px;
  text-align: center;
  
  width: 100%;
}
.logo {
  
  width: 200px;
  margin-right: 10px;
  top: 0%;
}

.wifi-icon{
  
  max-height: 2%;
  width: 80px;
  top: 0%;
  margin-left: 10px;
  margin-bottom: 130px;
}

.footer {
  
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #ffff;
  text-align: center;
  font-size: 15px;
  margin-top: -600px;
}

.contacts{
 
  margin-top: 60%;
  width: 100%;
  color: #202b61;
  
  
}

/* Media Query for Small Screens */
@media (max-width: 500px) {
  .footer {
    margin-top: 200px;
  }


}
.cont{
  
  font-size: 20px;
  font-weight: 700;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


</style>
