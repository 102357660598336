import { createRouter, createWebHistory } from 'vue-router'
import ProductList from '@/components/ProductList.vue';
import MpesaPay from '@/components/MpesaPay.vue';
import SubscriptionSuccess from '@/components/SubscriptionSuccess.vue';
import SubscriptionError from '@/components/SubscriptionError.vue';


const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }

  { path: '/', component: ProductList },
  {
    path: '/pay',
    name: 'pay',  // Keep this name for navigation purposes
    component: MpesaPay
  },
  { path: '/success', component: SubscriptionSuccess },
  { path: '/error', component: SubscriptionError },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
